@import "./assets/styles/style.scss";

header {
  box-sizing: border-box;
  max-width: 1600px;
  min-height: 80px;
  margin: 0 auto;
  text-align: right;
}

.social-media-links {
  display: flex;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 500;
  a {
    display: inline-block;
    font-family: "fontello";
    padding: 0.5em;
    border: 1px solid $linen;
    background-color: $blackish;
    transition: all 1s ease-in;
    &:hover {
      i {
        color: $linen;
        transition: color 300ms ease-in;
      }
    }
    @at-root .lightmode & {
      background-color: $linen;
      color: $steelblue;
      border: 1px solid $blackish;
      transition: all 1s ease-in;
      &:hover {
        i {
          color: $blackish;
        }
      }
    }
  }
  i {
    font-size: 2.5em;
    transition: color 300ms ease-in;
  }
}
