@import "./assets/fonts/fontello/css/fontello.css";
@import "./assets/styles/style.scss";

*:focus {
  outline: 2px dashed $skyblue;
}

body {
  font-size: 14px;
  background-color: $blackish;
  color: $linen;
  line-height: 1.5;
  margin: 0;
  font-family: "Lucida Console", Monaco, monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transition: all 1s ease-in;
  @include for-tablet-portrait-up {
    font-size: 16px;
  }
  @include for-desktop-up {
    font-size: 18px;
  }
  &.preload, &.preload * {
  transition: none !important;
}
}
.lightmode {
  background: $white;
  color: $blackish;
  transition: all 1s ease-in;
  a {
    color: $steelblue;
    transition: all 1s ease-in;
  }
}

h1 {
  font-size: 2em;
}
a {
  color: $skyblue;
  text-decoration: none;
  transition: all 1s ease-in;
  &:hover {
    text-decoration: underline;
  }
}

img {
  max-width: 100%;
  margin: 0 auto;
}

i {
  font-style: normal;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
