@import "../assets/styles/style.scss";

.Welcome {
  padding: 0 1em;
  margin-bottom: 4em;

  @include for-tablet-portrait-up {
    margin: 3em auto;
    padding: 0 3vw;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    min-height: 70vw;
  }
  @include for-desktop-up {
    min-height: 52vw;
  }
  i {
    color: $gold;
    height: 50px;
    transition: color 1s ease-in;
    &::before {
      display: block;
      font-size: 3em;
      margin: 0 auto 0.5em;
      transition: all 300ms ease-in;
    }
    @at-root .lightmode & {
      color: $steelblue;
      transition: all 1s ease-in;
    }
  }
}
button {
  background: none;
  border: none;
  border-radius: 0;
  color: $linen;
  font-family: "Lucida Console", Monaco, monospace;
  font-size: 1em;
  padding: 0.5em;
  cursor: pointer;
  transition: color 1s ease-in;
  @at-root .lightmode & {
    color: $blackish;
    transition: color 1s ease-in;
  }
  &:hover i::before {
      transform: scale(1.5);
      transition: all 300ms ease-in;
  }
}

.hexagon {
  @include for-tablet-portrait-up {
    box-sizing: border-box;
    position: absolute;
    background: url(/elements/hexagon-linen.svg) no-repeat;
    background-size: 100%;
    overflow: hidden;
    padding: 0 4%;
    display: flex;
    align-items: center;
    text-align: center;
    width: 39vw;
    height: 35vw;
    @at-root .lightmode & {
      background: url(/elements/hexagon-blackish.svg) no-repeat;
      background-size: 100%;
    }
  }
  @include for-desktop-up {
    width: 30vw;
    height: 26vw;
  }
  p {
    font-size: 1.2em;
  }
}
.whoami {
  @include for-tablet-portrait-up {
    top: 0;
    left: 0;
    font-size: 0.85em;
  }
  @include for-tablet-landscape-up {
    font-size: 1em;
  }
  @include for-desktop-up {
    top: 0;
    left: 3vw;
  }
}
.history {
  @include for-tablet-portrait-up {
    top: 16.8vw;
    left: 29vw;
  }
  @include for-desktop-up {
    top: 12.9vw;
    left: 25.3vw;
  }
}
.job {
  @include for-tablet-portrait-up {
    top: 0;
    left: 58vw;
  }
  @include for-desktop-up {
    top: 0;
    left: 47.6vw;
  }
}
.stack {
  @include for-tablet-portrait-up {
    top: 33.5vw;
    left: 58vw;
  }
  @include for-desktop-up {
    top: 25.8vw;
    left: 47.6vw;
  }
}
.mode-icons, .lightmode .mode-icons {
  @include for-tablet-portrait-up {
    background: transparent;
    display: flex;
    justify-content: center;
    top: 33.5vw;
    left: 0;
  }
  @include for-desktop-up {
    top: 25.8vw;
    left: 3vw;
  }
}
.hoi, .lightmode .hoi {
  @include for-tablet-portrait-up {
    background: transparent;
    font-size: 2em;
    display: flex;
    justify-content: center;
    top: -16.8vw;
    left: 29vw;
  }
  @include for-desktop-up {
    top: -12.9vw;
    left: 25.3vw;
  }
  span {
    animation-name: wave-animation; /* Refers to the name of your @keyframes element below */
    animation-duration: 2.5s; /* Change to speed up or slow down */
    animation-iteration-count: infinite; /* Never stop waving :) */
    transform-origin: 70% 70%; /* Pivot around the bottom-left palm */
    display: inline-block;
  }
  @keyframes wave-animation {
    0% {
      transform: rotate(0deg);
    }
    10% {
      transform: rotate(-10deg);
    } /* The following four values can be played with to make the waving more or less extreme */
    20% {
      transform: rotate(12deg);
    }
    30% {
      transform: rotate(-10deg);
    }
    40% {
      transform: rotate(9deg);
    }
    50% {
      transform: rotate(0deg);
    } /* Reset for the last half to pause */
    100% {
      transform: rotate(0deg);
    }
  }
}
