@import "../assets/styles/style.scss";

li,
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.Portfolio {
  max-width: 1200px;
  margin: 6em auto;
  padding: 0 1em;
  @include for-tablet-landscape-up {
    padding: 0 3em;
  }
  ul {
    @include for-tablet-portrait-up {
    columns: 2;
    column-gap: 3em;
    }
    @include for-tablet-landscape-up {
    columns: 3;
    }
  }
}

.portfolio-item {
  width: 80%;
  margin: 0 auto 3em;
  @include for-tablet-portrait-up {
    width: auto;
    margin: 0 0 3em;
    break-inside: avoid-column;
  }
  // .info {
  box-sizing: border-box;
  display: block;
  background: $blackish;
  box-sizing: border-box;
  border: 1px solid $linen;
  color: $linen;
  box-shadow: 1px 1px $linen, 2px 2px $linen, 3px 3px $linen, 4px 4px $linen,
    5px 5px $linen, 6px 6px $linen, 7px 7px $linen, 8px 8px $linen;
  transform: translateX(-8px) translateY(-8px);
  transition: background-color 1s ease-in, color 1s ease-in, border 1s ease-in,
    box-shadow 300ms ease-in, transform 300ms ease-in;
  &:hover,
  & .open {
    box-shadow: 0 0 $linen, 0 0 $linen, 0 0 $linen, 0 0 $linen, 0 0 $linen,
      0 0 $linen, 0 0 $linen, 0 0 $linen;
    transform: translateX(0) translateY(0);
    transition: all 300ms ease-in;
  }
  a {
    display: block;
    padding: 1em 1.5em;
    color: $linen;
    &:hover {
      text-decoration: none;
    }
  }
  h2 {
    color: $skyblue;
  }

  @at-root .lightmode & {
    background: $linen;
    color: $blackish;
    border: 1px solid $blackish;
    box-shadow: 1px 1px $blackish, 2px 2px $blackish, 3px 3px $blackish,
      4px 4px $blackish, 5px 5px $blackish, 6px 6px $blackish, 7px 7px $blackish,
      8px 8px $blackish;
    transform: translateX(-8px) translateY(-8px);
    transition: background-color 1s ease-in, color 1s ease-in, border 1s ease-in,
      box-shadow 300ms ease-in, transform 300ms ease-in;
    &:hover,
    & .open {
      box-shadow: 0 0 $blackish, 0 0 $blackish, 0 0 $blackish, 0 0 $blackish,
        0 0 $blackish, 0 0 $blackish, 0 0 $blackish, 0 0 $blackish;
      transform: translateX(0) translateY(0);
      transition: all 300ms ease-in;
    }
    a {
      color: $blackish;
    }
    h2 {
      color: $steelblue;
    }
  }
}

@include for-phone-only {
  h3 {
    display: inline;
    margin-right: 1em;
  }
  p {
    margin: 0.5em 0;
  }
}
